import { api } from "./../../api";

export const service_module = {
  state: () => ({}),

  getters: {},

  mutations: {},

  actions: {
    SERVICE_CREATE: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("services", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
};
