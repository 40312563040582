<template>
  <ion-row class="top-logos">
    <ion-col>
      <ion-img :src="right_image" />
      <div class="top-logos__title">{{ right_name }}</div>
    </ion-col>
    <ion-col>
      <ion-img :src="left_image" />
      <div class="top-logos__title">
        {{ left_name }}
      </div>
    </ion-col>
  </ion-row>
</template>

<script>
import { defineComponent } from "vue";

import { IonGrid, IonRow, IonCol, IonButton, IonImg } from "@ionic/vue";

import ENV from "../env";

export default defineComponent({
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonImg,
  },

  props: {
    right_name: {
      default: "",
    },

    left_name: {
      default: "",
    },
  },

  data: function () {
    return {
      right_image: 'https://static.wixstatic.com/media/510102_d12f98f8f2374de8ab05d2322d9f0224.png/v1/fill/w_334,h_106,al_c,usm_0.66_1.00_0.01,enc_auto/510102_d12f98f8f2374de8ab05d2322d9f0224.png',
      left_image: ENV.base_url + "storage/logos/login.png?cache=false",
    };
  },
});
</script>

<style scoped>
.top-logos {
  margin: 10px;
}

.top-logos__title {
  text-align: center;
  font-size: 16px;
  line-height: 1.5em;
}

ion-img {
  border-radius: 5px;
  padding: 5px;
  background: whitesmoke;
  height: 60px;
}
</style>
