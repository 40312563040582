<template>

    <i class="fas fa-clock" :class="status"></i>

</template>

<script>

    export default {

        props : {
            task : {}
        },

        data : function(){
            return {
                status : ''
            }
        },
        created : function(){
            
            switch(this.task.sla_status){
                case 'no_breach' : this.status = 'green'; break;
                case 'breached' : this.status = 'red'; break;
                case 'near_breach' : this.status = 'orange'; break;
            }
        }
    }
</script>