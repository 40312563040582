<template>
	<ion-item  v-for="task in tasks" >
		<ion-grid>
			<ion-row>
				<ion-col size="8" @click="goToTask(task.id)">
					<div><strong>{{ texts.id }}:</strong> {{ task.id }}</div>
					<!-- <div><strong>{{ texts.type }}:</strong> {{ task.type }}</div> -->
					<div><strong>{{ texts.contact }}:</strong> {{ task.contact }}</div>
					<div><strong>{{ texts.address }}:</strong> {{ task.address }}</div>
					
					<template v-if="type == 'open'">
						<div><strong>{{ texts.days_till_closure }}:</strong> {{ task.days_till_closure }}</div>
						<div><strong>{{ texts.due_date }}:</strong> {{ task.due_date }}</div> 
					</template>

					<template v-if="type == 'closed'">
						<div><strong>{{ texts.close_date }}:</strong> {{ task.close_date }}</div>
					</template>

					<div><strong>{{ texts.request_1 }}:</strong> {{ task.service_request_1 }}</div>
				</ion-col>
				<ion-col size="4">
					<div class="image-container">
						<task-sla-status-component class="task-sla-status" :task="task"/>
						<ion-thumbnail v-if="task.attachment">
							<img :src="task.attachment.thumbnail" 
								:href="task.attachment.original"
								data-fancybox="gallery" 
								:data-caption="task.attachment.name"
							/>
						</ion-thumbnail>
						<ion-thumbnail v-else-if="task.attachment_service">
							<img :src="task.attachment_service.thumbnail" 
								:href="task.attachment_service.original"
								data-fancybox="gallery" 
								:data-caption="task.attachment_service.name"
							/>
						</ion-thumbnail>
						<template v-else>
							<p>{{ texts.no_image }}</p>					
						</template>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-item>
	
</template>

<script>
	import { IonList, IonItem, IonLabel, IonAvatar, IonCol, IonRow, IonGrid, IonImg, IonThumbnail, IonChip } from "@ionic/vue";
	
	import TaskDetailsComponent from "./TaskDetailsComponent.vue";
	import TaskSlaStatusComponent from "./TaskSlaStatusComponent.vue";
	import { Fancybox } from "@fancyapps/ui";
	import "@fancyapps/ui/dist/fancybox.css";

	export default {
		components: {
			IonList,
			IonItem,
			IonLabel,
			IonAvatar,
			TaskDetailsComponent,
			TaskSlaStatusComponent,
			IonCol,
			IonRow,
			IonGrid,
			IonImg, IonThumbnail, IonChip
		},
		
		props: {
			texts : {},
			tasks: {
				default: function () {
					return [];
				},
			},
			type : {}
		},
		
		methods: {
			goToTask: function (task_id) {
				this.$router.push({
					name: "tasks.detail",
					params: {
						id: task_id,
					},
				});
			},
		},
	};
</script>

<style scoped>
	ion-avatar {
		border: none;
	}
	
	ion-item {
		margin-bottom: 1em;
	}
	
	ion-thumbnail {
		height: 50%;
		width: 100%;
	}
	
	.image-container {
		width: 100%;
		border-radius: 10px;
		overflow: hidden;
	}
	
	.task-sla-status {
		position: absolute;
		top: -3px;
		right: 0px;
		background: white;
		border-radius: 50%;
	}

	.image-container img{
		max-width: 60%;
	}
</style>
