<template>
    <template v-if="options">
        <template v-if="options.length == 0">
            <ion-item lines="none">
                <ion-label>{{ texts.no_options_available }}</ion-label>
            </ion-item>
            
        </template>

        <template v-else>

            <template v-if="options.length < 4">
                <ion-radio-group @ionChange="optionSelected" v-model="value">
                    <ion-item v-for="option in options" lines="none">
                        <ion-label  >{{ option[option_key] }}</ion-label>
                        <ion-radio slot="start" :value="option.id"></ion-radio>
                    </ion-item>                                                            
                </ion-radio-group>	
            </template>
            <template v-else>
                <ion-select v-model="value" @ionChange="optionSelected">
                    <ion-select-option 
                        v-for="option in options"
                        :value="option.id">{{ option[option_key] }}
                    </ion-select-option>
                </ion-select>
            </template>     
        </template>                   
    </template>
</template>

<script>

    import { IonLabel, IonItem, IonSelect, IonSelectOption, IonRadio, IonRadioGroup } from "@ionic/vue";

    export default {

        components : {
            IonLabel, IonItem, IonSelect, IonSelectOption, IonRadio, IonRadioGroup
        },

        emits : ['onChange'],

        props : {
            texts : {},
            option_key : {  
                default : function(){
                    return 'value'
                }
            },
            options : {

                default : function(){
                    return null;
                }
            },
            value_default : {
                default : function(){
                    return null;
                }
            }
        },

        data : function(){
            return {
                value : null
            }
        },

        created : function(){

            for(var i in this.options){

                //TODO: this should be changed, enters in conflict with the defaults in CreateServicePage
                if(this.options[i].internal_value == 'not_urgent'){
                    
                    this.value = this.options[i].id;

                    this.optionSelected();
                    break;
                }
            }
            
        },
        
        methods : {
            optionSelected : function(){
                this.$emit('onChange', this.value);
            }
        }
    }
</script>