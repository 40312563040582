import axios from 'axios';
import ENV from './env';
import store from './store'

export var api = {

	base : function(){

		return axios.create({
			baseURL: ENV.base_url_api(),
			withCredentials: false,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization' : 'Bearer ' + store.getters.get_access_token
			}
		});		
		
	},

	get : function(url, data){
		
		return this.base().get(url, { params : data });
	},

	post : function(url , data ){
		return this.base().post(url, data);
	},

	patch: function(url , data ){
		return this.base().patch(url, data);
	},

	delete: function(url , data ){
		return this.base().delete(url, data);
	},

	getFull : function(url ){
		return axios.create({
		    baseURL: '',
		    withCredentials: false,
		    headers: {
		        'Accept': 'application/json',
		        'Content-Type': 'application/json'
		    }
		}).get(url);
	}
}