<template>
	<ion-app>
		<ion-menu content-id="main" menu-id="main" :side="menu_side"  :class="{ 'direction-ltr' : menu_side == 'start', 'direction-rtl' : menu_side == 'end'}">
			<ion-header>
				<ion-toolbar color="light">
					<ion-title>{{ texts.menu }}</ion-title>
				</ion-toolbar>
			</ion-header>
			
			<ion-content>
				<ion-list>
					<ion-menu-toggle auto-hide="true" class="side-menu">
						<ion-list-header> {{ texts.tasks }} </ion-list-header>
						
						<ion-item button @click="goTo('tasks.open')">
							<i class="fas fa-bars-progress" slot="start"></i>
							<ion-label>{{ texts.my_open_tasks }}</ion-label>
						</ion-item>
						<ion-item button @click="goTo('tasks.closed')">
							<i class="fas fa-xmark" slot="start"></i>
							<ion-label>{{ texts.my_closed_tasks }}</ion-label>
						</ion-item>
						
						<ion-list-header> {{ texts.services }} </ion-list-header>
						
						<ion-item button @click="goTo('services.create')">
							<i class="fas fa-plus" slot="start"></i>							
							<ion-label>{{ texts.new_service }}</ion-label>
						</ion-item>
						
						<ion-list-header> {{ texts.user }} </ion-list-header>
						<ion-item button @click="logout">
							<i class="fa-solid fa-arrow-right-from-bracket" slot="start"></i>
							<ion-label>{{ texts.logout }}</ion-label>
						</ion-item>
					</ion-menu-toggle>
				</ion-list>
			</ion-content>
		</ion-menu>
		<ion-router-outlet id="main" />
	</ion-app>
</template>

<script>
	import { IonApp, IonRouterOutlet, IonListHeader, IonList, IonItem, IonMenu, IonContent, IonToolbar, IonHeader, IonLabel, IonTitle, IonMenuToggle } from "@ionic/vue";
	import { defineComponent } from "vue";
	import socketio from "socket.io-client";
	import Echo from "laravel-echo";
	import ENV from "./env.js";
	import axios from 'axios';

	export default defineComponent({
		name: "App",
		components: {
			IonApp,
			IonRouterOutlet,
			IonListHeader,
			IonList,
			IonItem,
			IonMenu,
			IonContent,
			IonToolbar,
			IonHeader,
			IonLabel,
			IonTitle,
			IonMenuToggle,
		},
		
		data : function(){
			return {
				menu_side : 'start',
				texts : {}
			}
		},

		mounted : function(){
			//webscoket
			/*const EchoInstance = new Echo({
				client: socketio,
				broadcaster: "socket.io",
				host: ENV.base_url_websockets,
				namespace: "App.Events",
			});
			
			EchoInstance.channel("mobile_app.localization").listen(".updated", (response) => {
				this.$store.commit("set_localization", null);
				
				this.$store.dispatch("LOCALIZATION", {}).then((response) => {
					var event = new CustomEvent("localization.update");
					document.dispatchEvent(event);
				})
				.catch((error) => {
					console.log("LOC ERRO", error);
				});
			});*/
			
		},

		created: function () {

			var headID = document.getElementsByTagName("head")[0];
			var link = document.createElement("link");
			link.type = "text/css";
			link.rel = "stylesheet";
			
			link.href = ENV.base_url + "css/mobile.css";
			headID.appendChild(link);


			this.$store.dispatch('GET_LOCALE', {}).then((response) => {

				if(response.direction == 'ltr') this.menu_side = 'start';
				else if(response.direction == 'rtl') this.menu_side = 'end';
			});

			this.updateLocalization();
			
			document.addEventListener("localization.update", () => {
				this.updateLocalization();
			});

		},

		methods: {
			updateLocalization: function () {
				this.$store.dispatch("LOCALIZATION_SCOPE", "menu").then((response) => {
					this.texts = response;
				});
			},

			goTo: function (route_name) {
				this.$router.push({
					name: route_name,
				});
			},
			
			logout: function () {
				this.$store.dispatch("LOGOUT", {}).then((response) => {
					this.$router.push({
						name: "auth.login",
					});
				});
			},
		},
	});
</script>

<style scoped>
	:root {
		--ion-safe-area-top: 20px;
		--ion-safe-area-bottom: 22px;
	}
	
	ion-item i {
		width: 15px;
		margin-right: 10px;
	}
	
	ion-menu ion-header ion-title {
		-webkit-padding-start: 20px;
		padding-inline-start: 20px;
		text-align: left;
	}
	
	[dir="rtl"] ion-menu ion-header ion-title {
		text-align: right;
	}
	
	ion-menu ion-list-header {
		margin-top: 10px;
	}
	
	ion-menu ion-list-header:first-child {
		margin-top: 0;
	}
</style>
