import { api } from "./../../api";

export const option_set_module = {
  getters: {},

  mutations: {},

  actions: {
    OPTION_DIRECTION: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("options/services/direction", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_CHANNEL: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("options/services/channel", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_URGENT: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("options/services/urgent", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_AREA: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("options/services/area", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_TYPE: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("options/services/type", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_REQUEST_1: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("options/services/request1", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_REQUEST_2: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("options/services/request2", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_REQUEST_3: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("options/services/request3", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_SITE : function({ commit }, data){
      return new Promise((resolve, reject) => {
        api
          .get("options/services/sites", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_SITE_TYPE : function({ commit }, data){
      return new Promise((resolve, reject) => {
        api
          .get("options/services/site-types", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_PUBLIC : function({ commit }, data){
      return new Promise((resolve, reject) => {
        api
          .get("options/services/public", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_SITE_CONTACT_ROLE : function({ commit }, data){
      return new Promise((resolve, reject) => {
        api
          .get("options/services/site-contact-roles", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_CITY : function({ commit }, data){
      return new Promise((resolve, reject) => {
        api
          .get("options/services/cities", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_STREET : function({ commit }, data){
      return new Promise((resolve, reject) => {
        api
          .get("options/services/streets", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    OPTION_SERVICE_PROCESS_DEFINITION: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("services/process-definition", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    QUICK_CODES: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("services/quick-codes", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

  },
};
