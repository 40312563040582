import { api } from './../../api';
import { DBStorage } from './../../DBStorage'

export const auth_module = {

    state : () => ({
		access_token : null
	}),

    getters : {

        get_access_token : function(state ){
            return state.access_token;
        }
    },

    mutations : {
        /**
		*	Set access token
		*	@method set_access_token
		*	@return { void }
		*/
		set_access_token : function(state , access_token ){

			state.access_token = access_token;

			DBStorage.set('access_token', access_token).then( (response) => {

			});
		}
    },

    actions : {
        LOGIN : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.post('auth/login', data).then((response ) => {
		
                    commit('set_access_token', response.data.access_token);
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        },

		CHECK_ACCESS_TOKEN : function({ commit }, data){
			return new Promise((resolve, reject) => {
				DBStorage.get('access_token').then((response) => {
					console.log(response);
				})
			})
		},

		LOGOUT : function({ commit }, data){
			
			return new Promise( (resolve, reject) => {
				//endpoint if needed

				commit('set_access_token', null);
				resolve({});
			});	

		},

		DEFAULTS : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.get('auth/defaults', data).then((response ) => {
		
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        },
    },

}