<template>
	<ion-page>
		<ion-header>
		</ion-header>
		<ion-content>
            <ion-grid>
                <company-images/>
                <slot />
            </ion-grid>
		</ion-content>
	</ion-page>
</template>

<script>

    import {
        IonPage, IonHeader, IonContent, IonGrid
    } from '@ionic/vue'
    import { defineComponent } from 'vue';

    import CompanyImages from '@/components/CompanyImages';

    export default defineComponent({
        components : { 
            IonPage, IonHeader, IonContent, IonGrid,
            CompanyImages
        }
    }) 

</script>