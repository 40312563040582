import { api } from "./../../api";

export const custom_data_module = {
  state: () => ({}),

  getters: {},

  mutations: {},

  actions: {
    GET_CUSTOM_DATA: function ({ commit }, data) {
        return new Promise((resolve, reject) => {
            api
            .get("custom-data/get", data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },
    UPDATE_CUSTOM_DATA: function ({ commit }, data) {
        return new Promise((resolve, reject) => {
          api
            .post("custom-data/update", data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
  
      GET_CUSTOM_DATA_ZONES: function ({ commit }, data) {
        return new Promise((resolve, reject) => {
          api
            .get("custom-data/zones", data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
  }

}