import { api } from './../../api';

export const entity_attachment_module = {

    state : () => ({
		
	}),

    getters : {

       
    },

    mutations : {
        
    },

    actions : {

		UPLOAD_ATTACHMENT : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
            
                var url = 'entity-attachments?model_id=' +  data.model_id + '&model_name=' +data.model_name;


				api.post(url, data).then((response ) => {
		
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        },
		
    },

}