import { api } from './../../api';

export const customer_module = {

    state : () => ({
		
	}),

    getters : {

       
    },

    mutations : {
        
    },

    actions : {
        CUSTOMERS : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.get('customers', data).then((response ) => {
	
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        }
		
    },

}