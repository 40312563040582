<template>
    <auth-layout>
        <ion-row>
            <ion-col>
              
              <ion-loading :is-open="loading"></ion-loading>
            </ion-col>
        </ion-row>
    </auth-layout>
</template>

<script>
    import { defineComponent } from 'vue';

    import { IonRow, IonCol, IonLoading } from '@ionic/vue';

    export default defineComponent({
        components : {
            IonRow, IonCol, IonLoading
        },

        data : function(){
            return {
                loading : true
            }
        },
        created : function(){

            this.loading = true;

            this.$store.dispatch('LOCALIZATION', {}).then((response) => {

                this.loading = false;
                
                this.$router.push({
                    name : 'auth.login'
                });
            }).catch((error) => {
                console.log("LOC ERRO", error);
            });
        }
    })
</script>