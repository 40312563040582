import { api } from './../../api';

export const tasks_module = {

    state : () => ({
		
	}),

    getters : {

       
    },

    mutations : {
        
    },

    actions : {
        TASKS : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.get('tasks', data).then((response ) => {
		
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        },
        
        TASK_DETAIL : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.get('tasks/' + data.id, data).then((response ) => {
		
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        },
        
        TASK_COMPLETE : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.post('tasks/' + data.id + '/complete', data).then((response ) => {
		
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        },

		TASK_ASSIGN : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.post('tasks/' + data.id + '/assign', data).then((response ) => {
		
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        },

		TASK_NOTES : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.post('tasks/' + data.id + '/notes', data).then((response ) => {
		
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        }
		
    },

}