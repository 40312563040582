import { createStore } from 'vuex';

import { localization_module } from './modules/LocalizationModule';
import { auth_module } from './modules/AuthModule';
import { dashboard_module  } from './modules/DashboardModule';
import { tasks_module  } from './modules/TasksModule';
import { employee_module  } from './modules/EmployeeModule';
import { sms_module  } from './modules/SMSModule';
import { entity_attachment_module  } from './modules/EntityAttachmentModule';
import { option_set_module  } from './modules/OptionSetModule';
import { contact_module  } from './modules/ContactModule';
import { customer_module  } from './modules/CustomerModule';
import { service_module  } from './modules/ServiceModule';
import { custom_data_module  } from './modules/CustomDataModule';

const store = createStore({
	state : {},
	modules : {
		auth_module,
		localization_module,
		dashboard_module,
		tasks_module,
		employee_module,
		sms_module,
		entity_attachment_module,
		option_set_module,
		customer_module,
		contact_module,
		service_module,
		custom_data_module
	}
});

export default store;