<template>
  <app-layout :page_default_back_link="'/dashboard'" :show_page_default_back_link="'show'">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-buttons class="task-detail-top-buttons">
            <ion-button expand="block" class="blue-content-button" @click="complete()" :disabled="task.completed">
              <template v-if="task.completed">
                {{ texts.completed }}
              </template>
              <template v-else>
                {{ texts.complete }}
              </template>
            </ion-button>
            <ion-button expand="block" class="pink-content-button" @click="modals.assign = true">{{ texts.assign }}</ion-button>
            <ion-button expand="block" class="violet-content-button" @click="modals.sms = true">{{ texts.sms }}</ion-button>
            <ion-button expand="block" class="violet-content-button" disabled>{{ texts.email }}</ion-button>
          </ion-buttons>

          <!-- task details (quiza no sea la mejor formar de hacer el display de la informacion) -->
          <ion-item>
            <ion-label position="stacked">{{ texts.id }}</ion-label>
            {{ task.id }}
          </ion-item>
          <ion-item>
            <ion-label position="stacked">{{ texts.owner }}</ion-label>
            {{ task.owner }}
          </ion-item>
          <ion-item>
            <ion-label position="stacked">{{ texts.type }}</ion-label>
            {{ task.type }}
          </ion-item>
          <ion-item>
            <ion-label position="stacked">{{ texts.contact }}</ion-label>
            <template v-if="contact">{{ contact.name }}</template>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">{{ texts.address }}</ion-label>
            {{ task.address }}
          </ion-item>

          <ion-item v-if="task.service">
            <ion-label position="stacked">{{ texts.service_description }}</ion-label>
            {{ task.service.description }}
          </ion-item>

          <ion-item>
            <ion-label position="stacked"
              >{{ texts.notes }}
              <ion-button class="green-content-button" @click="modals.notes = true"><i class="fa fa-edit"></i></ion-button>
            </ion-label>

            <div style="white-space: pre-line">{{ task.remarks }}</div>
          </ion-item>

          <!-- task images -->

          <ion-button class="green-content-button" @click="takePicture">
            <i class="fa-solid fa-camera"></i>
          </ion-button>

          <swiper :slides-per-view="4" :loop="false">
            <swiper-slide v-for="attachment in task.attachments">
              <template v-if="attachment.is_image">
                <img :src="attachment.thumbnail" :href="attachment.original" data-fancybox="gallery" :data-caption="attachment.name" />
              </template>
              <template v-else>
                <a :href="attachment.original">
                  {{ attachment.name }}
                </a>
              </template>
            </swiper-slide>

            <template v-if="service != null">
              <swiper-slide v-for="attachment in service.attachments">
                <template v-if="attachment.is_image">
                  <img :src="attachment.thumbnail" :href="attachment.original" data-fancybox="gallery" :data-caption="attachment.name" />
                </template>
                <template v-else>
                  <a :href="attachment.original">
                    {{ attachment.name }}
                  </a>
                </template>
              </swiper-slide>
            </template>
            
          </swiper>

          <!-- other task info, pending define button color -->
          <ion-button class="green-content-button" expand="block" @click="modals.service = true" v-if="service">{{ service.area }}, {{ service.type }}, {{ service.request_1 }}, {{ service.action_1 }}, {{ service.action_2 }}, {{ service.action_3 }}</ion-button>
          <ion-button class="green-content-button" expand="block" @click="modals.contact = true" v-if="contact">{{ contact.name }}, {{ contact.phone }}</ion-button>
          <ion-button class="green-content-button" expand="block" @click="modals.customer = true" v-if="customer">{{ customer.name }}</ion-button>
          <ion-button v-for="(zone, index) in zones" :key="index" class="green-content-button" expand="block" @click="modals.zones[index] = true">{{ zone.name }}</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- modals, esto es un poco chapucero, pero meh -->

    <!-- modal service detail -->
    <ion-modal :is-open="modals.service" v-if="service">
      <ion-header>
        <ion-toolbar>
          <ion-title class="title-side">{{ texts.service_detail }}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="modals.service = false">{{ texts.close }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">{{ texts.id }}</ion-label>
                {{ service.id }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.service_area }}</ion-label>
                {{ service.area }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.service_sub_area }}</ion-label>
                {{ service.type }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.request_1 }}</ion-label>
                {{ service.request_1 }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.opening_date }}</ion-label>
                {{ service.opening_date }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.due_date }}</ion-label>
                {{ service.due_date }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.process }}</ion-label>
                {{ service.process }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.stage }}</ion-label>
                {{ service.stage }}
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-modal>

    <!-- modal contact detail -->
    <ion-modal :is-open="modals.contact" v-if="contact">
      <ion-header>
        <ion-toolbar>
          <ion-title class="title-side">{{ texts.contact_detail }}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="modals.contact = false">{{ texts.close }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">{{ texts.id }}</ion-label>
                {{ contact.id }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.name }}</ion-label>
                {{ contact.name }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.phone }}</ion-label>
                {{ contact.phone }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.email }}</ion-label>
                {{ contact.email }}
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-modal>

    <!-- modal customer detail -->
    <ion-modal :is-open="modals.customer" v-if="customer">
      <ion-header>
        <ion-toolbar>
          <ion-title class="title-side">{{ texts.customer_detail }}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="modals.customer = false">{{ texts.close }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">{{ texts.id }}</ion-label>
                {{ customer.id }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.type }}</ion-label>
                {{ customer.type }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.name }}</ion-label>
                {{ customer.name }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.main_contact_name }}</ion-label>
                {{ customer.main_contact_name }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.main_contact_phone }}</ion-label>
                {{ customer.main_contact_phone }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.service_area }}</ion-label>
                {{ customer.service_area }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.rating }}</ion-label>
                {{ customer.rating }}
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-modal>

    <!-- modal close task (creo que esta no hace falta)-->

    <!-- modal assign task -->
    <ion-modal :is-open="modals.assign">
      <ion-header>
        <ion-toolbar>
          <ion-title class="title-side">{{ texts.assign_task }}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="modals.assign = false">{{ texts.close }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <div class="wrapper" style="height: 115%">
                <ion-item>
                  <ion-label position="stacked">{{ texts.id }}</ion-label>
                  {{ task.id }}
                </ion-item>
                <ion-item>
                  <ion-label position="stacked">{{ texts.type }}</ion-label>
                  {{ task.type }}
                </ion-item>
                <ion-item>
                  <ion-label position="stacked">{{ texts.contact }}</ion-label>
                  <template v-if="contact">{{ contact.name }}</template>
                </ion-item>
                <ion-item>
                  <ion-label position="stacked">{{ texts.address }}</ion-label>
                  {{ task.address }}
                </ion-item>
                <ion-item>
                  <ion-label position="stacked">{{ texts.owner }}</ion-label>
                  {{ task.owner }}
                </ion-item>

                <!-- este select va a tener muchas cosas, asi que lo mismo seria mejor input + listado o yo que se -->
                <ion-item>
                  <ion-label position="stacked">{{ texts.assign_to }}</ion-label>
                  <ion-input placeholder="Search..." @keyup="search" v-model="search_assign"></ion-input>
                  <span class="ion-error" v-if="!assign_form.ok">Required</span>
                </ion-item>

                <template v-if="show_assign_results">
                  <ion-radio-group v-model="assign_form.owner">
                    <ion-item lines="none">
                      <ion-label>{{ texts.teams }}</ion-label>
                    </ion-item>

                    <template v-if="teams_assign.length > 0">
                      <ion-item v-for="team in teams_assign" lines="none">
                        <ion-label>{{ team.name }}</ion-label>
                        <ion-radio slot="start" :value="'team_' + team.id"></ion-radio>
                      </ion-item>
                    </template>
                    <template v-else>
                      <ion-item lines="none">
                        <ion-label color="danger">{{ texts.no_results_for_teams }}</ion-label>
                      </ion-item>
                    </template>

                    <ion-item lines="none">
                      <ion-label>{{ texts.employees }}</ion-label>
                    </ion-item>
                    <template v-if="employees_assign.length > 0">
                      <ion-item v-for="employee in employees_assign" lines="none">
                        <ion-label>{{ employee.full_name }}</ion-label>
                        <ion-radio slot="start" :value="'employee_' + employee.id"></ion-radio>
                      </ion-item>
                    </template>
                    <template v-else>
                      <ion-item lines="none">
                        <ion-label color="danger">{{ texts.no_results_for_employees }}</ion-label>
                      </ion-item>
                    </template>
                  </ion-radio-group>
                </template>

                <ion-item>
                  <ion-label position="stacked">{{ texts.follow_up }}</ion-label>
                  <ion-checkbox slot="end" v-model="assign_form.follow_up"></ion-checkbox>
                </ion-item>
                <br />
                <ion-buttons>
                  <ion-button class="pink-content-button" @click="assign()">{{ texts.assign }}</ion-button>
                  <ion-button class="dark-content-button" @click="modals.assign = false">{{ texts.cancel }}</ion-button>
                </ion-buttons>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-modal>
    <!-- modal send sms -->
    <ion-modal :is-open="modals.sms">
      <ion-header>
        <ion-toolbar>
          <ion-title class="title-side">{{ texts.send_sms }}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="modals.sms = false">{{ texts.close }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <!-- poner aqui el task detail de arriba, llevarselo a un component, es el mismo codigo que arriba -->
              <ion-item>
                <ion-label position="stacked">{{ texts.id }}</ion-label>
                {{ task.id }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.type }}</ion-label>
                {{ task.type }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.contact }}</ion-label>
                <template v-if="contact">{{ contact.name }}</template>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.address }}</ion-label>
                {{ task.address }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.owner }}</ion-label>
                {{ task.owner }}
              </ion-item>

              <!-- este select va a tener muchas cosas, asi que lo mismo seria mejor input + listado o yo que se -->
              <ion-item>
                <ion-label position="stacked">{{ texts.to }}</ion-label>
                <ion-select interface="action-sheet" multiple v-model="sms_form.to">
                  <ion-select-option v-for="contact in task.contacts" :value="contact.id">{{ contact.name }} ({{ contact.phone }})</ion-select-option>
                </ion-select>

                <span class="ion-error" v-if="!sms_form.ok_to">Required</span>
              </ion-item>

              <ion-item>
                <ion-label position="stacked">{{ texts.message }}</ion-label>
                <ion-textarea rows="5" v-model="sms_form.text"></ion-textarea>

                <span class="ion-error" v-if="!sms_form.ok_text">Required</span>
              </ion-item>
              <ion-item lines="none">
                <ion-label position="stacked">
                  {{ texts.available_merge_tags }}
                </ion-label>

                <ul class="available-merge-tags">
                  <li v-for="mtag in sms_mtags" @click="addMtagToSmsText(mtag)" class="dark-content">{{ texts[mtag.name] }}</li>
                </ul>
              </ion-item>
              <br />

              <ion-buttons>
                <ion-button class="pink-content-button" @click="sms()">{{ texts.send_sms }}</ion-button>
                <ion-button class="dark-content-button" @click="modals.sms = false">{{ texts.cancel }}</ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-modal>
    <!-- modal send email -->

    <!-- modal notes -->
    <ion-modal :is-open="modals.notes">
      <ion-header>
        <ion-toolbar>
          <ion-title class="title-side">{{ texts.edit_notes }}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="modals.notes = false">{{ texts.close }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <!-- poner aqui el task detail de arriba, llevarselo a un component, es el mismo codigo que arriba -->
              <ion-item>
                <ion-label position="stacked">{{ texts.id }}</ion-label>
                {{ task.id }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.type }}</ion-label>
                {{ task.type }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.contact }}</ion-label>
                <template v-if="contact">{{ contact.name }}</template>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.address }}</ion-label>
                {{ task.address }}
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ texts.owner }}</ion-label>
                {{ task.owner }}
              </ion-item>

              <ion-item>
                <ion-label position="stacked">{{ texts.notes }}</ion-label>
                <ion-textarea rows="5" v-model="task.remarks"></ion-textarea>
              </ion-item>

              <ion-buttons>
                <ion-button class="pink-content-button" @click="updateNotes()">{{ texts.save }}</ion-button>
                <ion-button class="dark-content-button" @click="modals.notes = false">{{ texts.cancel }}</ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-modal>

    <!-- modal zones detail -->
    <ion-modal v-for="(zone, index) in zones" :key="index" :is-open="modals.zones[index]" :backdrop-dismiss="false">
      <ion-header>
        <ion-toolbar>
          <ion-title class="title-side">{{ zone.name }}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="modals.zones[index] = false">{{ texts.close }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-item v-for="field in zone.fields" :key="field.id">
                <ion-label position="stacked">{{ field.label }}</ion-label>
                {{ field.value }}
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-modal>  
    

    <ion-loading :is-open="loading"></ion-loading>
  </app-layout>
</template>

<script>
import { defineComponent } from "vue";

import { Camera, CameraResultType, CameraSource, Photo } from "@capacitor/camera";

import { Swiper, SwiperSlide } from "swiper/vue";

import {
  IonRow,
  IonCol,
  IonButton,
  IonButtons,
  IonSlides,
  IonSlide,
  IonModal,
  IonContent,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonItem,
  IonGrid,
  IonTitle,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonTextarea,
  IonImg,
  IonLoading,
  IonInput,
  IonRadioGroup,
  IonRadio,
} from "@ionic/vue";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import "swiper/css";
import "@ionic/vue/css/ionic-swiper.css";

import TaskDetailsComponent from "./components/TaskDetailsComponent.vue";

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    IonRow,
    IonCol,
    IonButton,
    IonButtons,
    TaskDetailsComponent,
    IonSlides,
    IonSlide,
    IonModal,
    IonContent,
    IonLabel,
    IonHeader,
    IonToolbar,
    IonItem,
    IonGrid,
    IonTitle,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    IonTextarea,
    IonImg,
    IonLoading,
    IonInput,
    IonRadioGroup,
    IonRadio,
  },

  data: function () {
    return {
      id: this.$route.params.id,
      loading: false,
      texts: {},

      modals: {
        service: false,
        contact: false,
        customer: false,
        assign: false,
        sms: false,
        email: false,
        notes: false,
        zones: [],
        location_data: false,
      },

      task: {},
      customer: null,
      service: null,
      contact: null,      
      location_data: null,

      search_assign: "",
      search_assig_timeout: null,
      employees_assign: [],
      teams_assign: [],
      show_assign_results: false,
      assign_form: {
        owner: null,
        follow_up: false,
        ok: true,
      },

      sms_form: {
        text: "",
        to: null,
        ok_to: true,
        ok_text: true,
      },

      sms_mtags: [
        {
          name: "contact_name",
          value: "%CONTACT_NAME%",
        },
      ],

      zones: [],
      current_locale : null
    };
  },

  ionViewWillEnter: async function () {
    await this.$store.dispatch("GET_LOCALE", {}).then((response) => {
      this.updateLocalization();
      this.current_locale = this.$store.getters.get_locale.locale;
    });

    document.addEventListener("localization.update", () => {
      this.updateLocalization();
    });
  },

  ionViewDidLeave: function () {
    document.removeEventListener("localization.update", null);
  },

  methods: {
    getTaskInfo: function () {
      this.loading = true;

      var params = {
        id: this.id,
        locale: this.$store.getters.get_locale.locale,
      };

      this.$store.dispatch("TASK_DETAIL", params).then((response) => {
        this.task = response.data.data;
        this.customer = response.data.data.customer;
        this.service = response.data.data.service;
        this.contact = response.data.data.contact;
        this.location_data = response.data.data.service.location_data
        this.loading = false;
      });

      this.$store.dispatch("GET_CUSTOM_DATA", { model_id : this.id, model_name : 'tasks', 'locale' : this.current_locale }).then((response) => {
        
        var zones = response.data.data.zones;
        this.zones = zones;

        zones.map((_, index) => {
          this.modals.zones[index] = false;
        });

      });
     /* let zones = [
        {
          name: "custom_data_services.zone_additional_data_permission",
          fields: [
            {
              id: 49,
              label: "custom_data_services.field_notes_label",
              value: "",
            },
            {
              id: 58,
              label: "custom_data_services.field_textarea_label",
              value: "long texttlkas asgkjasg",
            },
            {
              id: 56,
              label: "custom_data_services.field_campo4_label",
              value: "",
            },
            {
              id: 57,
              label: "custom_data_services.field_campo5_label",
              value: "",
            },
            {
              id: 55,
              label: "custom_data_services.field_campo3_label",
              value: "",
            },
            {
              id: 50,
              label: "custom_data_services.field_hobbies_label",
              value: "",
            },
            {
              id: 54,
              label: "custom_data_services.field_campo2_label",
              value: "",
            },
            {
              id: 53,
              label: "custom_data_services.field_campo1_label",
              value: "",
            },
          ],
        },
        {
          name: "custom_data_services.zone_sdfdsf",
          fields: [
            {
              id: 50,
              label: "custom_data_services.field_hobbies_label",
              value: "",
            },
            {
              id: 53,
              label: "custom_data_services.field_campo1_label",
              value: "",
            },
            {
              id: 59,
              label: "custom_data_services.field_bool_label",
              value: "",
            },
          ],
        },
      ];

      this.zones = zones;*/

      
    },

    updateLocalization: function () {
      this.$store.dispatch("LOCALIZATION_SCOPE", "task").then((response) => {
        this.texts = response;
        this.getTaskInfo();
      });
    },

    search: function () {
      clearTimeout(this.search_assign_timeout);

      this.search_assign_timeout = setTimeout(() => {

        if(this.search_assign.length < 3)
          return;

        this.loading = true;

        
        var params = {
          search: this.search_assign,
        };

        this.$store
          .dispatch("EMPLOYEE_TO_SELECT", params)
          .then((response) => {
            this.employees_assign = response.data.data;

            this.$store
              .dispatch("TEAM_TO_SELECT", params)
              .then((response) => {
                this.teams_assign = response.data.data;

                this.show_assign_results = true;
                this.loading = false;
              })
              .catch((error) => {
                console.log(error);
                this.loading = false;
              });
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }, 500);
    },

    assign: function () {
      this.assign_form.ok = true;

      if (!this.assign_form.owner) {
        this.assign_form.ok = false;
        return;
      }

      var params = {
        owner: this.assign_form.owner,
        follow_up: this.assign_form.follow_up,
        id: this.id,
      };

      this.$store
        .dispatch("TASK_ASSIGN", params)
        .then((response) => {
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message,
          });

          this.modals.assign = false;
          this.assign_form.owner = null;
          this.assign_form.follow_up = false;
          this.assign_form.ok = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addMtagToSmsText: function (mtag) {
      this.sms_form.text = this.sms_form.text + " " + mtag.value;
    },

    sms: function () {
      var params = {
        id: this.id,
        //file : image.base64String
      };

      this.$store
        .dispatch("TASK_UPLOAD_ATTACHMENT", params)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

      this.sms_form.ok_to = true;
      this.sms_form.ok_text = true;

      if (!this.sms_form.to) this.sms_form.ok_to = false;

      if (!this.sms_form.text) this.sms_form.ok_text = false;

      if (!this.sms_form.ok_text || !this.sms_form.ok_to) return;

      var params = {
        contact_ids: this.sms_form.to,
        text: this.sms_form.text,
      };

      this.loading = true;

      this.$store
        .dispatch("SMS_SEND", params)
        .then((response) => {
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message,
          });

          this.modals.sms = false;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    complete: function () {
      this.loading = true;

      var params = {
        id: this.id,
      };

      this.$store
        .dispatch("TASK_COMPLETE", params)
        .then((response) => {
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message,
          });

          this.task.completed = true;

          this.loading = false;
        })
        .catch((error) => {
          this.$notify({
            type: "warn",
            title: "Notification",
            text: error.response.data.message,
          });

          this.loading = false;
        });
    },

    updateNotes: function () {
      this.loading = true;

      var params = {
        id: this.id,
        notes: this.task.remarks,
      };

      this.$store
        .dispatch("TASK_NOTES", params)
        .then((response) => {
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message,
          });

          this.loading = false;

          this.modals.notes = false;
        })
        .catch((error) => {
          this.$notify({
            type: "warn",
            title: "Notification",
            text: error.response.data.message,
          });

          this.loading = false;
          this.modals.notes = false;
        });
    },

    async takePicture() {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.base64,
      });

      var params = {
        model_id: this.id,
        model_name: "tasks",
        file: image.base64String,
        extension: image.format,
      };

      this.$store
        .dispatch("UPLOAD_ATTACHMENT", params)
        .then((response) => {
          this.task.attachments.push(response.data.attachment);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
</script>

<style scoped>
.task-detail-top-buttons {
  display: flex;

  gap: 4px;
}

.task-detail-top-buttons ion-button {
  flex-grow: 1;
}
</style>
