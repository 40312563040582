import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import FirstPage from "../views/FirstPage.vue";
import LoginPage from "../views/auth/LoginPage.vue";
import DashboardPage from "../views/dashboard/DashboardPage.vue";
import MyOpenTasksPage from "../views/tasks/MyOpenTasksPage.vue";
import MyClosedTasksPage from "../views/tasks/MyClosedTasksPage.vue";
import TaskDetailPage from "../views/tasks/TaskDetailPage.vue";
import CreateServicePage from "../views/services/CreateServicePage.vue";
import { DBStorage } from "../DBStorage";
import store from "../store";
import { nextTick } from "vue";

const routes = [
  {
    path: "/",
    component: FirstPage,
    name: "firstpage",
  },

  //auth
  { path: "/auth/login", component: LoginPage, name: "auth.login" },

  //dashboard
  { path: "/dashboard", component: DashboardPage, name: "dashboard.index" },

  //tasks
  { path: "/my-open-tasks", component: MyOpenTasksPage, name: "tasks.open" },
  { path: "/my-closed-tasks", component: MyClosedTasksPage, name: "tasks.closed" },
  { path: "/tasks/:id", component: TaskDetailPage, name: "tasks.detail" },

  //services
  { path: "/services/create", component: CreateServicePage, name: "services.create" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  var result = await DBStorage.get("access_token");

  if (to.name != "auth.login" && to.name != "firstpage") {
    if (!store.getters.get_access_token) {
      if (!result) {
        return { name: "firstpage" };
      } else {
        store.commit("set_access_token", result);
      }
    }
  } else {
    if (result) {
      store.commit("set_access_token", result);
      return { name: "dashboard.index" };
    }
  }
});

export default router;
