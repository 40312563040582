<template>
	<app-layout :page_default_back_link="'/dashboard'" :show_page_default_back_link="'show'">
		<ion-row>
			<ion-col>
				<ion-list-header> {{ texts.my_open_tasks }} </ion-list-header>
				<br />
				<task-list-component :texts="texts" :tasks="tasks" v-if="finished_loading" :type="'open'"/>
			</ion-col>
		</ion-row>

		<ion-loading :is-open="loading"></ion-loading>
	</app-layout>
</template>

<script>
import { defineComponent } from "vue";

import { IonRow, IonCol, IonListHeader, IonLoading } from "@ionic/vue";

import TaskListComponent from "./components/TaskListComponent.vue";

export default defineComponent({
	components: {
		IonRow,
		IonCol,
		IonListHeader,
		TaskListComponent,
		IonLoading
	},
	
	data: function () {
		return {
			texts: {},
			loading : false,
			finished_loading : false,
			tasks : []
		};
	},
	
	ionViewWillEnter: function () {
		this.updateLocalization();
		
		document.addEventListener("localization.update", () => {
			this.updateLocalization();
		});

		
	},
	
	ionViewDidLeave: function () {
		document.removeEventListener("localization.update", null);
	},
	
	methods: {
		updateLocalization: function () {
			this.$store.dispatch("LOCALIZATION_SCOPE", "task").then((response) => {
				this.texts = response;
				this.getTasks();
			});
		},

		getTasks : function(){

			this.loading = true;
			
			var data = {
				status : 'open',
        		locale: this.$store.getters.get_locale.locale,
			}

			this.$store.dispatch('TASKS', data).then((response) => {
				this.tasks = response.data.data;
				this.loading = false;
				this.finished_loading = true;
			});
		}
	},
});
</script>
