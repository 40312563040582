import { api } from './../../api';

export const sms_module = {

    state : () => ({
		
	}),

    getters : {

       
    },

    mutations : {
        
    },

    actions : {
        SMS_SEND : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.post('sms', data).then((response ) => {
		
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        },
		
    },

}