import { api } from './../../api';
import { DBStorage } from './../../DBStorage'

export const localization_module = {

    state : () => ({
        locale : null,
		localization : null
	}),

    getters : {

        get_localization_scope : (state) => (scope ) => {

            scope = 'mobile_app_' + scope;

            return state.localization[state.locale][scope];
        },

        get_locale : (state) =>{

            return state.locale;
        }
    },

    mutations : {
        /**
		*	Set localization object
		*	@method set_localization
		*	@return { void }
		*/
		set_localization : function(state , localization ){

            state.localization = localization;

            DBStorage.set('localization', localization).then( (response) => {

			});

		},

        /**
		*	Set locale
		*	@method set_locale
		*	@return { void }
		*/
		set_locale : function(state , locale ){

            state.locale = locale;

            DBStorage.set('locale', locale).then( (response) => {
               
			});

		}
    },

    actions : {
        LOCALIZATION : function({ commit } , data ){
            return new Promise( (resolve, reject) => {
				
                api.get('localization').then((response ) => {
                    
                    commit('set_localization', response.data);
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

                
			});
        },

        LOCALIZATION_SCOPE : function ( { dispatch, commit, state }, scope ) {

            return new Promise((resolve, reject) => {

                scope = 'mobile_app_' + scope;
                
                dispatch('LOCALIZATION', {}).then((response) => {
                    resolve(response.data[state.locale.locale][scope]);
                });
                /*dispatch('GET_LOCALE', {}).then((response) => {
                    if(!state.localization){
                        DBStorage.get('localization').then((response) => {
                            commit('set_localization', response); 
                            resolve(response[state.locale.locale][scope]);
                        });
                    }else{
                        resolve(state.localization[state.locale.locale][scope]);
                    }   
                });   */     
            })
            
        },
        
        GET_LOCALE : function({ commit, state }, data){

            return new Promise((resolve, reject) => {

                DBStorage.get('locale').then((response) => {

                    if(!response)
                        response = {
                            locale : 'en',
                            direction : 'ltr'
                        }

                    commit('set_locale', response); 
                    resolve(response);
                });  

            });
        }
    },

}