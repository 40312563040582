<template>

    
</template>

<script>

    export default{

        props : {
            texts : {}
        }
    }
</script>