import { Storage } from '@ionic/storage';

//https://github.com/ionic-team/ionic-storage

/**
*	Class that defines the DBStorage (internal storage)
*	@namespace Storage.DBStorage
*	@class
*/
export class DBStorage{

	/**
	*	Set a key 
	*	@function set
	*	@memberof Storage.DBStorage
	*/
	static async set(key , value ){

		var db = new Storage();

		await db.create();

		return await db.set(key, value);

	}

	/**
	*	Get a key 
	*	@function get
	*	@memberof Storage.DBStorage
	*/
	static async get(key ){

		var db = new Storage();

		await db.create();

		return await db.get(key);

	}
}