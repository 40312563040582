import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import { IonicVue } from '@ionic/vue';
import Notifications from '@kyvg/vue3-notification'
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import axios from 'axios';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/mazemate.css';


/* Layouts */
import AuthLayout from './layouts/AuthLayout.vue';
import AppLayout from './layouts/AppLayout.vue';


const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(Notifications);

app.component('auth-layout', AuthLayout);
app.component('app-layout', AppLayout);

router.isReady().then(() => {
  app.mount('#app');
});

defineCustomElements(window);