<template>
  <app-layout
    :page_default_back_link="'/dashboard'"
    :show_page_default_back_link="'show'"
  >
    <ion-row>
      <ion-col v-if="texts">
        <ion-list-header> {{ texts.new_service }} </ion-list-header>
        <ion-button @click="submit" expand="block" class="green-content-button">
          {{ texts.save }}
        </ion-button>
        <ion-item>
          <ion-label position="stacked">{{ texts.owner }}</ion-label>
          Me
        </ion-item>
        <ion-item v-if="show_direction">
          <ion-label position="stacked">{{ texts.direction }}</ion-label>
          <radio-list
            :options="direction"
            :texts="texts"
            @onChange="directionSelected"
          />
          <span class="ion-error ion-error-top" v-if="errors.direction">
            Required
          </span>
        </ion-item>
        <ion-item v-if="show_channel">
          <ion-label position="stacked">{{ texts.channel }}</ion-label>
          <radio-list
            :options="channel"
            :texts="texts"
            @onChange="channelSelected"
          />
          <span class="ion-error ion-error-top" v-if="errors.channel">
            Required
          </span>
        </ion-item>
        <ion-item v-if="urgent">
          <ion-label position="stacked">{{ texts.urgent }}</ion-label>
          <radio-list
            :options="urgent"
            :texts="texts"
            @onChange="urgentSelected"
          />
          <span class="ion-error ion-error-top" v-if="errors.urgent">
            Required
          </span>
        </ion-item>
        <ion-item button @click="buttonClick()">
          <searchable-list-modal
            :title="texts.service_quick_codes"
            v-if="quick_codes"
            :texts="texts"
            :options="quick_codes"
            option_key="name"
            @onChange="quickCodeSelected"
            ref="openModalQuickCodes"
          />
          <span class="ion-error ion-error-top" v-if="errors.quick_codes">
            Required
          </span>
          <p>{{ quick_code_label }}</p>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">{{ texts.description }}</ion-label>
          <ion-textarea
            v-model="description"
            @ionChange="descriptionChanged"
          ></ion-textarea>
          <span class="ion-error ion-error-top" v-if="errors.description">
            Required
          </span>
        </ion-item>

        <!-- location data -->
        <!--
					TODO:
					- configurar los onchange
					- house number, house entrance y apart number son campos sueltos
				-->
        <ion-list-header> {{ texts.location_data }} </ion-list-header>
        <br />

				<!-- service location site -->
				<ion-item v-if="service_sites">
          <ion-label position="stacked">{{ texts.service_site }}</ion-label>
          <radio-list
            :options="service_sites"
            :texts="texts"
						@onChange="serviceSiteChanged"
          />       
          <span class="ion-error ion-error-top" v-if="errors.site">
            Required
          </span>   
        </ion-item>
        
        <template v-if="site_1_selected === false">
          <!-- service location site type -->
          <ion-item v-if="service_site_types">
            <ion-label position="stacked">{{ texts.service_site_type }}</ion-label>
            <radio-list
              :options="service_site_types"
              :texts="texts"
              @onChange="serviceSiteTypeChanged"
            />
          </ion-item>

          <!-- service location is public -->
          <ion-item v-if="service_location_public">
            <ion-label position="stacked">{{ texts.service_location_public }}</ion-label>
            <radio-list
              :options="service_location_public"
              :texts="texts"
              @onChange="servicePublicChanged"
            />
          </ion-item>

          <!-- service location contact -->
          <ion-item>
            <ion-label position="stacked">{{ texts.service_location_contact }}</ion-label>
            <ion-input @keyup="searchContacts" v-model="search_contact"></ion-input>
          </ion-item>

          <ion-radio-group v-model="form.location_data.service_contact" @ionChange="onSelectContact">
            <template v-if="contacts">
              <template v-if="contacts.length > 0">
                <ion-item v-for="contact in contacts" lines="none">
                  <ion-label>{{ contact.text }}</ion-label>
                  <ion-radio slot="start" :value="contact.id"></ion-radio>
                </ion-item>
              </template>
              <template v-else>
                <ion-item lines="none">
                  <ion-label color="danger">{{ texts.no_results_for_contacts }}</ion-label>
                </ion-item>
              </template>
            </template>
          </ion-radio-group>

          <!-- site_contact_relationship_role -->
          <ion-item v-if="site_contact_relationship_role">
            <ion-label position="stacked">{{ texts.site_contact_relationship_role }}</ion-label>
            <radio-list
              :options="site_contact_relationship_role"
              :texts="texts"
              @onChange="serviceSiteContactRoleChanged"
            />
          </ion-item>
        </template>

        <template v-if="site_1_selected === true">
          <!-- city -->
          <ion-item v-if="service_cities">
            <ion-label position="stacked">{{ texts.service_city }}</ion-label>
            <radio-list
              :options="service_cities"
              :texts="texts"
              @onChange="serviceCityChanged"
            />
            <span class="ion-error ion-error-top" v-if="errors.city">
            Required
          </span> 
          </ion-item>

          <!-- street -->
          <ion-item>
            <ion-label position="stacked">{{ texts.service_street }}</ion-label>
            <radio-list
              :options="service_streets"
              :texts="texts"
              @onChange="serviceStreetChanged"
            />
            <span class="ion-error ion-error-top" v-if="errors.street">
            Required
          </span> 
          </ion-item>

          <!-- house number -->
          <ion-item>
            <ion-label position="stacked">{{ texts.service_house_number }}</ion-label>
            <ion-input
              v-model="form.location_data.house_number"
            ></ion-input>
            <span class="ion-error ion-error-top" v-if="errors.house_number">
            Required
          </span> 
          </ion-item>

          <!-- house entrance -->
          <ion-item>
            <ion-label position="stacked">{{ texts.service_house_entrance }}</ion-label>
            <ion-input
              v-model="form.location_data.house_entrance"
            ></ion-input>
          </ion-item>

          <!-- apartment number -->
          <ion-item>
            <ion-label position="stacked">{{ texts.service_apartment_number }}</ion-label>
            <ion-input
              v-model="form.location_data.apartment_number"
            ></ion-input>
          </ion-item>
        </template>

        <!-- zones -->
				<template v-if="zones.length > 0">
					<ion-list-header> {{ texts.zones }} </ion-list-header>
					<br />
					<ion-accordion-group v-for="zone in zones" :key="zone.id" value="zones">
						<ion-accordion value="zones">
							<ion-item slot="header">
								<ion-label>{{ zone.name }}</ion-label>
							</ion-item>

							<div slot="content">
								<ion-item v-for="field in zone.fields" :key="field.id">
									<ion-label v-if="field.type == 'boolean'">
										{{ field.label }}
									</ion-label>
									<ion-label v-else position="stacked">
										{{ field.label }}
									</ion-label>
									<ion-input
										v-if="field.type == 'text'"
										v-model="zoneFieldsInfo[field.id]"
									/>
									<ion-item v-if="field.type == 'long_text'">
										<ion-textarea v-model="zoneFieldsInfo[field.id]">
										</ion-textarea>
									</ion-item>
									<ion-input
										v-if="field.type == 'number'"
										type="number"
										v-model="zoneFieldsInfo[field.id]"
									/>
									<ion-input
										v-if="field.type == 'date'"
										type="date"
										v-model="zoneFieldsInfo[field.id]"
									/>
									<ion-toggle
										v-if="field.type == 'boolean'"
										v-model="zoneFieldsInfo[field.id]"
										:checked="false"
									/>
									<ion-select
										v-if="field.type == 'option'"
										placeholder="Select One"
										v-model="zoneFieldsInfo[field.id]"
									>
										<ion-select-option
											v-for="(option, index) in field.options"
											:key="index"
											:value="option.value"
										>
											{{ option.label }}
										</ion-select-option>
									</ion-select>
								</ion-item>
							</div>
						</ion-accordion>
					</ion-accordion-group>
				</template>

        <ion-button @click="submit" expand="block" class="green-content-button">
          {{ texts.save }}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-loading :is-open="loading"></ion-loading>
  </app-layout>
</template>

<script>
import { defineComponent } from "vue";

import {
  IonAccordion,
  IonToggle,
  IonTextarea,
  IonAccordionGroup,
  IonRow,
  IonCol,
  IonListHeader,
  IonLoading,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonRadio,
  IonRadioGroup,
  IonInput,
  IonButton,
} from "@ionic/vue";

import RadioList from "@/components/RadioList.vue";
import SearchableListModal from "@/components/SearchableListModal.vue";

export default defineComponent({
  components: {
    IonAccordion,
    IonTextarea,
    IonAccordionGroup,
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonListHeader,
    IonLoading,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    RadioList,
    IonToggle,
    SearchableListModal,
  },

  data: function () {
    return {
      //TODO - Refactor this and put it in server side
      defaults: {
        direction: "inbound",
        channel: "mobile",
      },

      texts: null,
      loading: false,
      finished_loading: false,
      direction: null,
      channel: null,
      urgent: null,
      quick_codes: null,
      quick_code_label: "",
      processes: null,
      description: null,
      search_contact: "",
      contacts: null,
      timeout_search_contact: null,
      search_customer: "",
      customers: null,
      timeout_search_customers: null,
      current_locale: null,

			service_sites : null,
			service_site_types : null,
			service_location_public : null,
			service_site_contact : null,
			site_contact_relationship_role : null,
			service_cities : null,
			service_streets : null,

      site_1_selected : null,

      form: {
        service_area: null,
        direction: null,
        channel: null,
        urgent: null,
        description: null,
        process: null,
        contact_id: null,
        customer_id: null,
        location_data : {
					site : null,
					site_type : null,
					service_public : null,
					service_contact : null,
					site_contact_relationship_role : null,
					city : null,
					street : null,
					house_number : null,
					house_entrance : null,
					apartment_number : null
				}
      },

      errors: {
        direction: false,
        channel: false,
        urgent: false,
        quick_codes: false,
        description: false,
        process: false,
        contact_id: false,
        customer_id: false,
      },

      show_direction: false,
      show_channel: false,
      zones: [],
      zoneFieldsInfo: [],
    };
  },

  ionViewWillEnter: async function () {
    this.updateLocalization();

    document.addEventListener("localization.update", () => {
      this.updateLocalization();
    });

    await this.$store.dispatch("GET_LOCALE", {}).then((response) => {
      this.current_locale = this.$store.getters.get_locale.locale;
    });

    this.$store.dispatch("DEFAULTS", {}).then((response) => {
			
      if (response.data.data.contact) {
        this.search_contact = response.data.data.contact.first_name;
        this.searchContacts();
        this.form.contact_id = response.data.data.contact.id;
        this.onSelectContact();

        if (response.data.data.customer) {
          this.form.customer_id = response.data.data.customer.id;
        }
      }
    });

    this.$store
      .dispatch("GET_CUSTOM_DATA_ZONES", {
        entity: "services",
        locale: this.current_locale,
      })
      .then((response) => {
        // populate zones
        let zonesResponse = response.data.data;
        this.zones = response.data.data;

        // create vue data/models
        let zoneFieldsInfo = [];

        for (let zone of zonesResponse) {
          for (let field of zone.fields) {
            // if field is a boolean, set default value to false to prevent the checked state
            if (field.type == "boolean") {
              zoneFieldsInfo[field.id] = false;
            } else {
              zoneFieldsInfo[field.id] = "";
            }
          }
        }

        // clear null key/values and assign to vue data
        this.zoneFieldsInfo = Object.fromEntries(
          Object.entries(zoneFieldsInfo).filter(([_, v]) => v != null)
        );
      });
  },

  ionViewDidLeave: function () {
    document.removeEventListener("localization.update", null);
  },

  methods: {
    updateLocalization: function () {
      this.loading = true;

      this.$store
        .dispatch("LOCALIZATION_SCOPE", "create_service")
        .then((response) => {
          this.texts = response;
          this.loading = false;

          this.getInitialData();
        });
    },

    getInitialData() {
      this.$store
        .dispatch("OPTION_DIRECTION", { locale: this.current_locale })
        .then((response) => {
          this.direction = response.data.data;
          var found_default = false;

          for (var i in this.direction) {
            if (
              this.direction[i].internal_value.toLowerCase() ==
              this.defaults.direction
            ) {
              this.form.direction = this.direction[i].id;
              this.show_direction = false;
              found_default = true;
              break;
            }
          }

          if (!found_default) {
            this.show_direction = true;
          }
        });

      this.$store
        .dispatch("OPTION_CHANNEL", { locale: this.current_locale })
        .then((response) => {
          this.channel = response.data.data;

          var found_default = false;

          for (var i in this.channel) {
            if (
              this.channel[i].internal_value.toLowerCase() ==
              this.defaults.channel
            ) {
              this.form.channel = this.channel[i].id;
              this.show_channel = false;
              found_default = true;
            }
          }

          if (!found_default) {
            this.show_channel = true;
          }
        });

      this.$store
        .dispatch("OPTION_URGENT", { locale: this.current_locale })
        .then((response) => {
          this.urgent = response.data.data;
        });

      this.$store
        .dispatch("OPTION_SERVICE_AREA", { locale: this.current_locale })
        .then((response) => {
          this.service_areas = response.data.data;
        });

      this.$store.dispatch("QUICK_CODES").then((response) => {
        this.quick_codes = response.data.data;
      });

      //get location data info
      this.$store
        .dispatch('OPTION_SERVICE_SITE', { locale : this.current_locale })
				.then((response) => {
					this.service_sites = response.data.data;
				});

			this.$store
        .dispatch('OPTION_SERVICE_SITE_TYPE', { locale : this.current_locale })
				.then((response) => {
					this.service_site_types = response.data.data;
				});

			this.$store
        .dispatch('OPTION_SERVICE_PUBLIC', { locale : this.current_locale })
				.then((response) => {
					this.service_location_public = response.data.data;
				});

			this.$store
        .dispatch('OPTION_SERVICE_SITE_CONTACT_ROLE', { locale : this.current_locale })
				.then((response) => {
					this.site_contact_relationship_role = response.data.data;
				});

			this.$store
        .dispatch('OPTION_SERVICE_CITY', { locale : this.current_locale })
				.then((response) => {
					this.service_cities = response.data.data;
				});
    },

    urgentSelected: function (value) {
      this.form.urgent = value;
    },

    channelSelected: function (value) {
      this.form.channel = value;
    },

    directionSelected: function (value) {
      this.form.direction = value;
    },

    quickCodeSelected: function (value) {
      for (var i in this.quick_codes) {
        if (this.quick_codes[i].id == value) {
          this.quick_code_label = this.quick_codes[i].name;
          this.form.quick_codes = value;
          this.form.service_area = this.quick_codes[i].area.id;
          this.form.service_type = this.quick_codes[i].type.id;
          this.form.request_1 = this.quick_codes[i].request_1.id;
          this.form.request_2 = this.quick_codes[i].request_2.id;
          this.form.request_3 = this.quick_codes[i].request_3.id;
          break;
        }
      }

      this.getAvailableProcess();
    },

    getAvailableProcess: function () {
      var params = {
        area: this.form.service_area,
        type: this.form.service_type,
        request1: this.form.request_1,
        request2: this.form.request_2,
        request3: this.form.request_3,
        locale: this.current_locale,
      };
      this.$store
        .dispatch("OPTION_SERVICE_PROCESS_DEFINITION", params)
        .then((response) => {

          if(response.data.data[0]){
            this.form.process = response.data.data[0].id;
          }
          
        });
    },

    descriptionChanged: function (e) {
      this.form.description = e.detail.value;
    },

    searchContacts: function ($event, force = false) {
      if (this.search_contact.length > 2 || force) {
        clearTimeout(this.timeout_search_contact);

        if (force) {
          if (!this.form.customer_id) return;
        }

        this.timeout_search_contact = setTimeout(() => {
          var params = {
            customer: this.form.customer_id,
            search: this.search_contact,
          };

          this.$store.dispatch("CONTACTS", params).then((response) => {
            this.contacts = response.data.data;
          });
        }, 500);
      } else {
        this.form.contact_id = null;
        this.contacts = null;

        this.customers = null;
        this.form.customer_id = null;
      }
    },

    searchCustomers: function ($event, force = false) {
      if (this.search_customer.length > 2 || force) {
        if (force) {
          if (!this.form.contact_id) return;
        }

        clearTimeout(this.timeout_search_customer);

        this.timeout_search_customer = setTimeout(() => {
          var params = {
            contact: this.form.contact_id,
            search: this.search_customer,
          };

          this.$store.dispatch("CUSTOMERS", params).then((response) => {
            this.customers = response.data.data;
          });
        }, 500);
      } else {
        this.form.customer_id = null;
        this.customers = null;

        this.form.contact_id = null;
        this.contacts = null;
      }
    },

    onSelectCustomer: function () {
      if (this.form.contact_id) return;

      this.searchContacts(null, true);
    },

    onSelectContact: function () {
      if (this.form.customer_id) return;

      this.searchCustomers(null, true);
    },

    submit: function () {
      var params = {
        area_id: this.form.service_area,
        channel_id: this.form.channel,
        contact_id: this.form.contact_id,
        customer_id: this.form.customer_id,
        direction_id: this.form.direction,
        process_definition_id: this.form.process,
        request_1_id: this.form.request_1,
        request_2_id: this.form.request_2,
        request_3_id: this.form.request_3,
        description: this.form.description,
        type_id: this.form.service_type,
        urgent_id: this.form.urgent,
				location_data : this.form.location_data
      };

      var zoneParams = {
        model_id: 1,
        model_name: "services",
        fields: [],
      };

      Object.entries(this.zoneFieldsInfo).map(([key, value]) => {
        if (value != "") {
          zoneParams.fields.push({
            id: key,
            value: value,
          });
        }
      });

      var is_valid = this.validate();
  
      if (!is_valid) return;

      this.$store
        .dispatch("SERVICE_CREATE", params)
        .then((response) => {
          this.$notify({
            type: "success",
            title: "Notification",
            text: response.data.message,
          });

          zoneParams.model_id = response.data.service.id;

          this.$store.dispatch("UPDATE_CUSTOM_DATA", zoneParams).then((r) => {
            this.$router.push({
              name: "dashboard.index",
            });
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    validate: function () {
      this.checkRequiredField("description");
      this.checkRequiredField("direction");
      this.checkRequiredField("channel");
      this.checkRequiredField("quick_codes");
      this.checkRequiredField("contact_id");
      this.checkRequiredField("customer_id");
      this.checkRequiredField("urgent");

      this.checkRequiredFieldInLocation('site');

      if(this.site_1_selected){
        this.checkRequiredFieldInLocation('city');
        this.checkRequiredFieldInLocation('street');        
        this.checkRequiredFieldInLocation('house_number');
      }

      var is_valid = true;
      for (var i in this.errors) {
        if (this.errors[i]) {
          is_valid = false;
          break;
        }
      }

      return is_valid;
    },

    checkRequiredField: function (field) {
      if (!this.form[field]) {
        this.errors[field] = true;
      } else {
        this.errors[field] = false;
      }
    },

    checkRequiredFieldInLocation: function (field) {
      if (!this.form['location_data'][field]) {
        this.errors[field] = true;
      } else {
        this.errors[field] = false;
      }
    },

    buttonClick: function () {
      this.$refs.openModalQuickCodes.setOpen(true);
    },

		serviceSiteChanged : function(value){

			this.form.location_data.site = value;

      for(var i in this.service_sites){
        if(this.service_sites[i].internal_value == 'SITE_1' && value == this.service_sites[i].id){
          this.site_1_selected = true;
          break;
        }else{
          this.site_1_selected = false;
        }
      }
		},

		serviceSiteTypeChanged : function(value){
			this.form.location_data.site_type = value;
		},

		servicePublicChanged : function(value){
			this.form.location_data.service_public = value;
		},

		serviceServiceContactChanged : function(value){
			this.form.location_data.service_contact = value;
		},

		serviceSiteContactRoleChanged : function(value){
			this.form.location_data.site_contact_relationship_role = value;
		},

		serviceCityChanged : function(value) {
			this.form.location_data.city = value;

			var params = {
				city : value,
				locale : this.current_locale
			}

			this.$store
        .dispatch('OPTION_SERVICE_STREET', params)
				.then((response) => {
					this.service_streets = response.data.data;
				});
		},

		serviceStreetChanged : function(value){

			this.form.location_data.street = value;
		}
  },
});
</script>
