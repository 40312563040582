<template>
    <auth-layout>
        <div class="login-container">
            <ion-row>
                <ion-col>
                    <ion-list-header class="login-form-title"> {{ texts.login }} </ion-list-header>
                    
                    <ion-item lines="none" class="login-form-item">
                        <ion-label position="stacked">{{ texts.email }}</ion-label>
                        <ion-input v-model="credentials.email"></ion-input>
                    </ion-item>
                    <ion-item lines="none" class="login-form-item">
                        <ion-label position="stacked">{{ texts.password }}</ion-label>
                        <ion-input type="password" v-model="credentials.password"></ion-input>
                    </ion-item>
                    
                    <p v-if="error_login" class="red">{{ texts.incorrect_credentials }}</p>
                </ion-col>
            </ion-row>
            
            <ion-row class="login-form-footer">
                <ion-col size="8">
                    <a href="">{{ texts.forgot_your_password }}</a>
                </ion-col>
                <ion-col size="4">
                    <ion-button class="gray-content-button" @click="login">{{ texts.submit }}</ion-button>
                </ion-col>
            </ion-row>
        </div>
        
		<ion-loading :is-open="loading"></ion-loading>
    </auth-layout>
</template>

<script>
    import { defineComponent } from "vue";
    
    import { IonRow, IonCol, IonInput, IonItem, IonLabel, IonImg, IonButton, IonListHeader, IonLoading } from "@ionic/vue";
    
    export default defineComponent({
        components: {
            IonRow,
            IonCol,
            IonInput,
            IonItem,
            IonLabel,
            IonImg,
            IonButton,
            IonListHeader,
            IonLoading
        },
        
        data: function () {
            return {

                loading : false,
                credentials: {
                    email: "",
                    password: "",
                },
                
                error_login: false,
                
                texts: {},
            };
        },
        
        created: function () {
            this.$store.dispatch("LOCALIZATION_SCOPE", "auth").then((response) => {
                this.texts = response;
            });
        },
        
        methods: {
            login: function () {

                this.loading = true;
                this.error_login = false;
                
                this.$store
                .dispatch("LOGIN", this.credentials)
                .then((response) => {
                    this.credentials.email = "";
                    this.credentials.password = "";

                    this.loading = false;
                    this.$router.push({
                        name: "dashboard.index",
                    });
                })
                .catch((error) => {
                    console.log(error);
                    
                    this.error_login = true;
                    this.loading = false;
                });
            },
        },
    });
</script>

<style scoped>
    .login-form-title {
        margin-bottom: 1em;
        padding: 0;
    }
    
    .login-container {
        padding: 8px 16px;
        margin: 16px;
        background: whitesmoke;
        border-radius: 10px;
    }
    
    .login-form-item {
        --background: transparent;
        --padding-start: 0;
    }
    
    .login-form-item ion-input {
        background: white;
        --padding-start: 10px;
        border-radius: 5px;
    }
    
    .login-form-footer ion-col {
        display: flex;
        align-items: center;
    }
</style>
