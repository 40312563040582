<template>
	<ion-page >
		<ion-header>
			<ion-toolbar :class="{ 'direction-rtl' : direction == 'rtl', 'direction-ltr' : direction == 'ltr' }">
				<ion-buttons slot="start">
					<ion-menu-button auto-hide="false" color="medium" menuId="main"></ion-menu-button>
				</ion-buttons>
				<ion-title>
					<ion-buttons class="ion-justify-content-center">
						<ion-back-button text="Back" color="medium" :default-href="page_default_back_link" v-if="show_page_default_back_link == 'show'">e</ion-back-button>
					</ion-buttons>
				</ion-title>
				<!-- TODO: load locales from server -->
				<ion-buttons class="lang-selector" slot="end">
					<ion-button @click="changeLocale('en', 'ltr')" :class="{ activated: active_locale == 'en' }">EN</ion-button>
					<ion-button @click="changeLocale('he', 'rtl')" :class="{ activated: active_locale == 'he' }">HE</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		
		<ion-content :class="{ 'direction-rtl' : direction == 'rtl', 'direction-ltr' : direction == 'ltr' }">
			<company-images />
			<slot />
		</ion-content>
		
		<notifications position="top right" />

		<ion-loading :is-open="loading"></ion-loading>
	</ion-page>
</template>

<script>
	
	
	import { 
			IonPage, IonHeader, IonContent, IonToolbar, IonTitle, IonList, 
			IonItem, IonMenu, IonListHeader, IonIcon, IonLabel, IonButtons, IonButton, 
			IonGrid, IonBackButton, IonMenuToggle, menuController, IonMenuButton,
			IonLoading
	} from "@ionic/vue";
	
	import CompanyImages from "@/components/CompanyImages";
	
	export default {
		components: {
			IonPage,
			IonHeader,
			IonContent,
			IonToolbar,
			IonTitle,
			IonList,
			IonItem,
			IonMenu,
			IonListHeader,
			IonIcon,
			IonLabel,
			IonButtons,
			IonButton,
			IonGrid,
			IonBackButton,
			IonMenuToggle,
			IonMenuButton,
			CompanyImages,
			IonLoading
		},
		
		props: {
			show_page_default_back_link: {
				default: function () {
					return "show";
				},
			},
			page_default_back_link: {},
		},
		
		data: function () {
			return {
				loading : false,
				active_locale : 'en',
				direction : 'ltr'
			};
		},
		mounted: function () {
			//configure locale

			this.$store.dispatch('GET_LOCALE', {}).then((response) => {
				
				this.active_locale = response.locale;
				this.direction = response.direction;
			});

			
		},
		
		methods: {
			changeLocale: function (locale, direction) {

				this.loading = true;
				this.$store.commit("set_locale", { locale: locale, direction : direction});
				
				//var event = new CustomEvent("localization.update");
				//document.dispatchEvent(event);
				
				setTimeout( () => {
					this.loading = false;
					location.reload();
				}, 2000);
				
			},
		},
	};
</script>

<style scoped>
	.lang-selector.sc-ion-buttons-ios-s ion-button {
		font-size: 15px;
		--padding-start: 0px;
		--padding-end: 2px;
	}
</style>
