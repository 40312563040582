<template>
	<app-layout>
		<ion-row>
			<ion-col>
				<div>
					<ul class="badges-button">
						<li><ion-badge class="green-content rounded-badge">{{ tasks_by_sla_status.no_breach }}</ion-badge></li>
						<li><ion-badge class="orange-content rounded-badge">{{ tasks_by_sla_status.near_breach }}</ion-badge></li>
						<li><ion-badge class="red-content rounded-badge">{{ tasks_by_sla_status.breached }}</ion-badge></li>
					</ul>
					<ion-button size="large" expand="block" class="blue-content-button" @click="goToMyOpenTasks()">{{ texts.my_open_tasks }}</ion-button>
				</div>
				<ion-button size="large" expand="block" class="blue-content-button" @click="goToMyClosedTasks()">{{ texts.my_closed_tasks }}</ion-button>
				<ion-button size="large" expand="block" class="green-content-button" @click="goToCreateService()">{{ texts.new_service }}</ion-button>
			</ion-col>
		</ion-row>
		<ion-loading :is-open="loading"></ion-loading>
	</app-layout>
</template>

<script>
	import { defineComponent } from "vue";
	
	import { IonRow, IonCol, IonButton, IonBadge, IonLoading } from "@ionic/vue";
	
	export default defineComponent({
		components: {
			IonRow,
			IonCol,
			IonButton,
			IonBadge,
			IonLoading
		},
		
		data: function () {
			return {
				texts: {},
				tasks_by_sla_status : {
					breached : null,
					near_breach : null,
					no_breach : null
				},
				loading : false
			};
		},
		
		ionViewWillEnter: function () {
			this.updateLocalization();
			
			document.addEventListener("localization.update", () => {
				this.updateLocalization();
			});

			this.loading = true;
			
		},
		
		ionViewDidLeave: function () {
			document.removeEventListener("localization.update", null);
		},
		
		methods: {
			updateLocalization: function () {
				this.$store.dispatch("LOCALIZATION_SCOPE", "dashboard").then((response) => {
					this.texts = response;

					this.updateDashboard()
				});
			},
			
			updateDashboard : function(){

				var that = this;
				this.$store.dispatch('DASHBOARD', {}).then((response) => {

					this.tasks_by_sla_status.breached = response.data.data.tasks_by_sla_status.breached ?? 0;
					this.tasks_by_sla_status.near_breach = response.data.data.tasks_by_sla_status.near_breach ?? 0;
					this.tasks_by_sla_status.no_breach = response.data.data.tasks_by_sla_status.no_breach ?? 0;

					this.loading = false;
				}).catch((response) => {
					that.$store.dispatch("LOGOUT", {}).then((response) => {

						this.loading = false;
						this.$router.push({
							name: "auth.login",
						});
					});
				});
			},

			goToMyOpenTasks: function () {
				this.$router.push({
					name: "tasks.open",
				});
			},
			
			goToMyClosedTasks: function () {
				this.$router.push({
					name: "tasks.closed",
				});
			},
			
			goToCreateService: function () {
				this.$router.push({
					name: 'services.create'
				});
			},
		},
	});
</script>
