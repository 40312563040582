import { api } from './../../api';

export const employee_module = {

    state : () => ({
		
	}),

    getters : {

       
    },

    mutations : {
        
    },

    actions : {
        EMPLOYEE_TO_SELECT : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.get('me/employees/all', data).then((response ) => {
		
        
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        },

        TEAM_TO_SELECT : function({ commit }, data ){
            return new Promise( (resolve, reject) => {
				
				api.get('me/teams/all', data).then((response ) => {
		
					resolve(response);	
									
				}).catch((error ) => {
					reject(error);
				});

			});
        }
		
    },

}