<template>
  <template v-if="options">
    <template v-if="options.length == 0">
      <ion-label position="stacked">{{ title }}</ion-label>
      <ion-item lines="none">
        <ion-label>{{ texts.no_options_available }}</ion-label>
      </ion-item>
    </template>

    <template v-else>
      <ion-label position="stacked" @click="setOpen(true)">{{
        title
      }}</ion-label>
      <ion-modal :is-open="isOpen" :backdropDismiss="false">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ title }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="setOpen(false)">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-searchbar @ionChange="searchChange"></ion-searchbar>
          <ion-radio-group @ionChange="optionSelected" v-model="value">
            <ion-item v-for="option in options" lines="none">
              <ion-label>{{ option[option_key] }}</ion-label>
              <ion-radio slot="start" :value="option.id"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-content>
      </ion-modal>
    </template>
  </template>
</template>

<script>
import {
  IonLabel,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonModal,
  IonButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
} from "@ionic/vue";

export default {
  components: {
    IonLabel,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonSearchbar,
    IonModal,
    IonButton,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
  },

  emits: ["onChange"],

  props: {
    texts: {},
    title: {
      default: function () {
        return "value";
      },
    },
    openModal: {
      default: function () {
        return false;
      },
    },
    option_key: {
      default: function () {
        return "value";
      },
    },
    options: {
      default: function () {
        return null;
      },
    },
    value_default: {
      default: function () {
        return null;
      },
    },
  },

  data: function () {
    return {
      value: null,
      isOpen: false,
    };
  },

  created: function () {
    for (var i in this.options) {
      //TODO: this should be changed, enters in conflict with the defaults in CreateServicePage
      if (this.options[i].internal_value == "not_urgent") {
        this.value = this.options[i].id;

        this.optionSelected();
        break;
      }
    }
  },

  methods: {
    optionSelected: function () {
      this.$emit("onChange", this.value);
    },
    setOpen: function (isOpen) {
      this.isOpen = isOpen;
    },
    searchChange: function (event) {
      const query = event.target.value.toLowerCase();
      const items = Array.from(
        document.querySelector("ion-modal ion-radio-group").children
      );
      requestAnimationFrame(() => {
        items.forEach((item) => {
          const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
          item.style.display = shouldShow ? "block" : "none";
        });
      });
    },
  },
};
</script>
